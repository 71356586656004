export const LOGIN = {
  EMAIL: 'Please enter email',
  PASSWORD: 'Please enter password',
  TYPE: 'Email is invalid',
  MIN: 'Password greater than or equals 8 character',
  MAX: 'Password less than or equals 20 character'
}

export const EDIT_USER = {
  EMAIL: 'Please input email',
  NAME: 'Please input name',
  PHONE: 'Please input phone number',
  TYPE_EMAIL: 'Email is invalid',
  NAME_LENGTH: 'Please input 4-15 characters',
}

export const ADD_ORG = {
  NAME_REQUIRE: 'Please input name',
  NAME_MIN: 'Please input 3-15 characters',
  NAME_MAX: 'Please input 3-15 characters',
  PASS_ORG_MIN: 'Please input 3-15 characters',
  PASS_ORG_MAX: 'Please input 3-15 characters',
  PASS_ORG_REQUIRE: 'Please input organization',
  ICON_REQUIRE: 'Please select icon',
  ORG_KEY_REQUIRE: 'Please input organization key',
  ORG_KEY_MIN: 'Please input 8-32 characters',
  ORG_KEY_MAX: 'Please input 8-32 characters',
  PASS_ORG_PATTERN: 'Only input letters and numbers are allowed',
  URL_REQUIRE: 'Please input url site organization'
}
