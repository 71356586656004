
export const upperCaseFirstLetter = (letter) => {
  return letter.charAt(0).toUpperCase() + letter.slice(1);
}

export const convertStringKey = (text) => {
  // const result = text.replace( /([A-Z])/g, " $1" );
  const result = text.replace(/([A-Z]+)/g, " $1").replace(/^,/, "");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export const shortenAddress = (address) => {
  return address && `${address.slice(0,4)}...${address.slice(-7)}`
}
