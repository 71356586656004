import React, { useEffect, useState } from 'react';
import moment from "moment";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Avatar, Tabs,
  Descriptions, Table, Spin,
} from 'antd';
import {
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import heic2any from 'heic2any';

import Layout from '../../../layouts';
import {
  WrapperAvatar,
  WrapperContent,
} from './styled';
import {
  getDetailUserById,
  getAllTransactionsByWalletId,
} from '../../../services/user';
import {
  columnsPremiseEntry,
  columnsTransactions,
} from './columns';
import {
  APP_API_URL,
} from '../../../configs';
import { convertStringKey } from '../../../helpers';

const { TabPane } = Tabs;

const labelStyle = {
  fontWeight: 600
};

const UserDetail = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { user_id: userId } = params;
  const [userDetail, setUserDetail] = useState({});
  const [premiseEntry, setPremiseEntry] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [image, setImage] = useState('');
  const [vaccineData, setVaccineData] = useState({});
  const [mm2hData, setMm2hData] = useState({});
  const [otherData, setOtherData] = useState([]);
  const [extension, setExtension] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = {
      user_id: userId
    }
    fetchDetailUserById(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchDetailUserById = async (params) => {
    try {
      const res = await getDetailUserById(params);
      const { code, data } = res.data;
      if (code === '200') {
        const listPass = [ ...data.passs ];
        let otherNewData = [];
        listPass.forEach(pass => {
          const { category } = pass;
          if (category.name === 'Vaccine') {
            setVaccineData({ ...pass });
          } else if (category.name === 'MM2H') {
            setMm2hData({ ...pass });
          }
          if (category.name !== 'Vaccine' && category.name !== 'MM2H') {
            otherNewData = [...otherNewData, pass];
          }
        });
        setOtherData([...otherNewData]);

        const newPremiseEntrys = data.premiseEntrys.sort((a, b) => new Date(b.checkinAt).getTime() - new Date(a.checkinAt).getTime() > 0 ? 1 : -1 );
        setUserDetail({ ...data });
        setPremiseEntry([...newPremiseEntrys]);
        const { facial } = data;
        setImage(facial ? facial.src : '');
        const img = facial ? facial.src : ''
        const extension = img.split('.')[1];
        if (extension === 'HEIC') {
          setLoading(true);
          setExtension(extension);
          convertToHeic(APP_API_URL + img);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const convertToHeic = (url) => {
    fetch(url)
    .then((res) => res.blob())
    .then((blob) =>
      heic2any({
        blob,
        toType: "image/jpeg",
        quality: 1, // cuts the quality and size by half
      })
    )
    .then((conversionResult) => {
      const url = URL.createObjectURL(conversionResult);
      setImage(url);
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
        // see error handling section
    });
  }

  const handleChangeTab = (key) => {
   if (key === '4') {
    fetchAllTransactions();
   }
  }

  const fetchAllTransactions = async () => {
    try {
      const res = await getAllTransactionsByWalletId(userDetail.walletId);
      const { data } = res;
      if (data.code === '200') {
        const newTransSort = data.data.sort((a, b) => new Date(b.createdt).getTime() - new Date(a.createdt).getTime() > 0 ? 1 : -1)
        setAllTransactions(newTransSort);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const renderOtherData = (dataOther) => {
    return (
      <>
        { dataOther.map ((item, index) => {
          const { category, data } = item;
          return (
            <Descriptions.Item key={index} labelStyle={labelStyle} label={category.name} span={12} >
              { Object.keys(data).map((key, index2) => {
                return (
                  <>
                    { convertStringKey(key) } : { data[key] }
                    <br />
                  </>
                )
              })}
            </Descriptions.Item>
          )
        })}
      </>
    )
  }

  return (
   <Layout>
     <WrapperAvatar>
       <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
       >
        { image ? <img src={ extension === 'HEIC' ? image : APP_API_URL + image} alt="avatar" className="avatar" /> : <Avatar shape="square" size={115} icon={<UserOutlined />} /> }
       </Spin>
      <span className="username">{ userDetail.name }</span>
     </WrapperAvatar>
     <WrapperContent>
        <Tabs defaultActiveKey="1" onChange={handleChangeTab} >
          <TabPane key="1" tab={<span className="tab">Information</span>} >
            <Descriptions bordered>
              <Descriptions.Item labelStyle={labelStyle} label="Name" span={3}>{ userDetail.name }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Email">{ userDetail.email }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="WalletId" span={2}>{ userDetail.walletId }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Phone" span={2}>{ userDetail.phone }</Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane key="2" tab={<span className="tab">All history</span>}>
            <Table
              style={{ overflow: "auto" }}
              bordered
              dataSource={premiseEntry}
              columns={columnsPremiseEntry}
            />
          </TabPane>
          <TabPane key="3" tab={<span className="tab">All pass</span>}>
            <Descriptions bordered>
              { !_.isEmpty(vaccineData) && (
                <Descriptions.Item  labelStyle={labelStyle} label={ vaccineData.category && vaccineData.category.name } span={12} >
                  UniqueID : { vaccineData.data && vaccineData.data.uniqueIdentificationNo }
                  <br />
                  Issued by : { vaccineData.data && vaccineData.data.issulingOrganisation }
                  <br />
                  <ul>
                    { vaccineData.data && vaccineData.data.doses && vaccineData.data.doses.map((dose, index) => (
                      <li key={index}>
                        Dose : { index + 1 }
                        <div> Batch : { dose.batch }</div>
                        <div> Day issued : { dose.date }</div>
                        <div> Manufacturer : { dose.manufacturer }</div>
                      </li>
                    )).reverse() }
                  </ul>
                </Descriptions.Item>
              ) }
              { !_.isEmpty(mm2hData) && (
                <Descriptions.Item  labelStyle={labelStyle} label={mm2hData.category && mm2hData.category.name } span={12} >
                  {/* { !_.isEmpty(mm2hData) && Object.keys(mm2hData.data).map(each => (
                    <p>{convertStringKey(each)}: {mm2hData.data[each]}</p>
                  ))} */}

                  <p>{`Name : ${mm2hData.data.name} ${mm2hData.data.surname}`}</p>
                  <p>{`Nationality : ${mm2hData.data.nationality}`}</p>
                  <p>{`Passport Number : ${mm2hData.data.passportNumber}`}</p>
                  <p>{`Application ID : ${mm2hData.data.applicationID}`}</p>
                  <p>{`Application Step : ${mm2hData.data.step}`}</p>
                  <p>{`Status : ${mm2hData.data.status}`}</p>
                  <p>{`Payment Status : ${mm2hData.data.orderStatus}`}</p>
                  <p>{`Last Updated At : ${moment(mm2hData.data.updatedAt).format('YYYY-MM-DD HH:mm:ss')}`}</p>
                </Descriptions.Item>
              ) }
              { !_.isEmpty(otherData) && renderOtherData(otherData) }
            </Descriptions>
          </TabPane>
          <TabPane key="4" tab={<span className="tab">All transaction</span>}>
            <Table
              style={{ overflow: "auto" }}
              bordered
              columns={columnsTransactions}
              dataSource={allTransactions}
            />
          </TabPane>
        </Tabs>
     </WrapperContent>
   </Layout>
  )
}

export default UserDetail;
