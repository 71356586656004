import { Space, Button, Tooltip } from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';

const renderAction = (id, data, detail) => {
  return (
    <Space>
      <Tooltip
        title="Detail"
      >
        <Button type="primary" onClick={() => detail(id, data)} icon={<EyeOutlined />} />
      </Tooltip>
    </Space>
  )
}

export const columnMerchant = (detail) => {
  return [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
      editable: true,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "35%",
      editable: true,
      align: "center",
    },
    {
      title: "Address",
      dataIndex: "address",
      width: "35%",
      editable: true,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "10%",
      editable: true,
      align: "center",
      render: (id, data) => renderAction(id, data, detail),
    },
  ]
}
