import { Descriptions } from 'antd';

import { FABRIC_URL } from '../../../configs';
import { shortenAddress } from '../../../helpers';

const labelStyle = {
  fontWeight: 600
};


const renderLinkTx = (txhash) => {
  return <a href={FABRIC_URL + `?tab=transactions&transId=${txhash}`} target="_blank" rel="noreferrer" >{ shortenAddress(txhash) }</a>
}

const renderDetailUser = (id, info) => {
  if (info) {
    const { data } = info;
    const { userId, username, walletId, actionType } = data;
    const { eforcenment } = info;
    const { status } = eforcenment;

    return (
      <Descriptions bordered>
        <Descriptions.Item label="User Infomation" span={12} labelStyle={labelStyle} >
          Username: <a href={`/users/detail?user_id=${userId}`} > { username } </a>
          <br />
          Wallet ID: { walletId }
          <br />
          Action Type: { actionType ? 'Approved' : 'Rejected' }
        </Descriptions.Item>

        <Descriptions.Item label="Enforcenment" span={12} labelStyle={labelStyle} >
          Warning Level: { status }
        </Descriptions.Item>
      </Descriptions>
    )
  }
}

export const columnsTransactions = [
  {
    title: "Tx Hash",
    dataIndex: "txhash",
    width: "10%",
    editable: true,
    align: "center",
    render: (txhash) => renderLinkTx(txhash)
  },
  {
    title: "Chain Code",
    dataIndex: "chaincodename",
    width: "5%",
    editable: true,
    align: "center",
  },
  {
    title: "Information Detail",
    dataIndex: "data",
    width: "65%",
    editable: true,
    align: "center",
    render: (id, info) => renderDetailUser(id, info),
  },
  {
    title: "Created at",
    dataIndex: "createdt",
    width: "20%",
    editable: true,
    align: "center",
  },
]
