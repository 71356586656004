import IconLogo from '../assets/images/logo.svg';
import IconCheckSuccess from '../assets/images/check-success.png';
import IconMM2H from '../assets/images/icon/mm2h.svg';
import IconVaccine from '../assets/images/icon/vaccine.svg';
import IconVehicle from '../assets/images/icon/vehicle.svg';
import IconPersonal from '../assets/images/icon/personal.svg';
import IconEducation from '../assets/images/icon/education.svg';
import IconMarket from '../assets/images/icon/market.svg';
import IconHospital from '../assets/images/icon/hospital.svg';
import IconNew from '../assets/images/icon/new.svg';
import IconProperty from '../assets/images/icon/property.svg';
import IconBank from '../assets/images/icon/bank.svg';
import IconImmigration from '../assets/images/icon/immigration.png';
import IconJPN from '../assets/images/icon/jpn.png';
import IconPDRM from '../assets/images/icon/pdrm.png';

const Icons = {
  IconLogo,
  IconCheckSuccess,
}

const IconsOrganization = [
  {key: 1, value: '/images/organizations/jpn.svg', path: IconJPN},
  {key: 2, value: '/images/organizations/immigration.svg', path: IconImmigration},
  {key: 3, value: '/images/organizations/pdrm.svg', path: IconPDRM},
  {key: 4, value: '/images/organizations/mm2h.svg', path: IconMM2H},
  {key: 5, value: '/images/organizations/vaccine.svg', path: IconVaccine},
  {key: 6, value: '/images/organizations/vehicle.svg', path: IconVehicle},
  {key: 7, value: '/images/organizations/personal.svg', path: IconPersonal},
  {key: 8, value: '/images/organizations/education.svg', path: IconEducation},
  {key: 9, value: '/images/organizations/bank.svg', path: IconBank },
  {key: 10, value: '/images/organizations/market.svg', path: IconMarket},
  {key: 11, value: '/images/organizations/hospital.svg', path: IconHospital},
  {key: 12, value: '/images/organizations/property.svg', path: IconProperty},
  {key: 13, value: '/images/organizations/new.svg', path: IconNew},
];

export {
  Icons,
  IconsOrganization,
}
