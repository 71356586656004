import React from 'react';
import {
  Modal, Form, Input,
  Select, 
} from 'antd';

import { IconsOrganization } from '../../constants/images';
import {
  WrapperOption,
} from './styled';
import {
  ADD_ORG,
} from '../../constants/validate';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

const ModalComponent = ({
  title,
  isShowModal,
  handleSubmitForm,
  handleCancel,
  isUpdate,
  form,
}) => {

  return (
    <Modal
      title={title}
      visible={isShowModal}
      onCancel={handleCancel}
      onOk={form.submit}
      width={650}
    >
      <Form
        { ...layout }
        form={form}
        onFinish={handleSubmitForm}
      >

        { !isUpdate && (
          <>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: ADD_ORG.NAME_REQUIRE },
                { min: 3, message: ADD_ORG.NAME_MIN },
                { max: 15, message: ADD_ORG.NAME_MAX }
              ]}
            >
              <Input placeholder="Name..." maxLength={15} />
            </Form.Item>
            <Form.Item
              label="Organization"
              name="passOrg"
              rules={[
                { required: true, message: ADD_ORG.PASS_ORG_REQUIRE },
                { min: 3, message: ADD_ORG.PASS_ORG_MIN },
                { max: 15, message: ADD_ORG.PASS_ORG_MAX },
                { pattern: new RegExp(/^[a-zA-Z0-9]*$/), message: ADD_ORG.PASS_ORG_PATTERN }
              ]}
            >
              <Input placeholder="Pass organization..." />
            </Form.Item>
          </>
        ) }

        <Form.Item
          label="Icon"
          name="icon"
          rules={[
            { required: true, message: ADD_ORG.ICON_REQUIRE }
          ]}
        >
          <Select
            placeholder="Icon..."
          >
            { IconsOrganization.map(value => {
              return (
                <Select.Option value={value.value}>
                  <WrapperOption>
                    <img src={value.path} alt="icon" width="20" />
                  </WrapperOption>
                </Select.Option>
              )
            }) }
          </Select>
        </Form.Item>

        <Form.Item
          label="URL"
          name="url"
          rules={[
            { required: true, message: ADD_ORG.URL_REQUIRE }
          ]}
        >
          <Input placeholder="URL site plugin..." />
        </Form.Item>

        { isUpdate && (
          <>
            <Form.Item
              label="Organization Key"
              name="orgKey"
              rules={[
                { required: true, message: ADD_ORG.ORG_KEY_REQUIRE },
                { min: 8, message: ADD_ORG.ORG_KEY_MIN },
                { max: 32, message: ADD_ORG.ORG_KEY_MAX },
              ]}
            >
              <Input maxLength={32} />
            </Form.Item>

            <Form.Item
              label="Active"
              name="active"
            >
              <Select>
                <Select.Option value={true} > Enable </Select.Option>
                <Select.Option value={false} > Disable </Select.Option>
              </Select>
            </Form.Item> 
          </>
        )}

      </Form>
    </Modal>
  )
}

export default ModalComponent;
