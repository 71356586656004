import styled from "styled-components";

const WrapperHeader = styled.div`
  display: flex;
  padding: 10px 0px;
`;

const WrapperTable = styled.div`
  .pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
`;

export {
  WrapperHeader,
  WrapperTable,
}
