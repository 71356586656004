import React from 'react';
import {
  Avatar,
  Dropdown,
  Menu,
} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';

import {
  WrapperHeader
} from './styled';

const HeaderComponent = (props) => {
  const {
    collapsed,
    toggle,
    handleLogout,
    userInfo,
  } = props;

  const renderMenu = () => {
    return (
      <Menu style={{ width: 120 }}>
        <Menu.Item>{ userInfo.name }</Menu.Item>
        <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
      </Menu>
    )
  }

  const renderAvatar = () => {
    return (
      <div className="box-avatar">
        <Avatar icon={<UserOutlined />} />
        <DownOutlined className="icon" />
      </div>
    )
  }

  return (
    <WrapperHeader>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <Dropdown trigger="click" overlay={renderMenu} placement="bottomCenter" arrow >
        { renderAvatar() }
      </Dropdown>
    </WrapperHeader>
  );
}

export default HeaderComponent;
