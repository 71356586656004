import styled from 'styled-components';

const WrapperAvatar = styled.div`
  display: flex;
  .username {
    margin-left: 10px;
    font-size: 1.7em;
    font-weight: 700;
  }
  .avatar {
    width: 115px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const WrapperContent = styled.div`
  .tab {
    font-size: 1.5em;
    .label {
      font-weight: 700;
    }
  }
`;

export {
  WrapperAvatar,
  WrapperContent,
}
