import { Button } from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';

const renderAction = (id, data, edit) => {
  return (
    <Button type="primary" onClick={() => edit(id, data)} icon={<EditOutlined />} >Edit</Button>
  )
}

const renderValue = (id, data) => {
  if (data && data.key === 'password') {
    return '*'.repeat(data.value.length);
  }
  return data.value;
}

export const columns = (edit) => {
  return [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
      editable: true,
      align: "center",
    },
    {
      title: "Key",
      dataIndex: "key",
      width: "40%",
      editable: true,
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "value",
      width: "40%",
      editable: true,
      align: "center",
      render: (id, data) => renderValue(id, data)
    },
    {
      title: "Action",
      dataIndex: "id",
      width: "15%",
      editable: true,
      align: "center",
      render: (id, data) => renderAction(id, data, edit)
    }
  ];
}
