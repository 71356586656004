import React, { useState, useCallback, useEffect } from 'react';
import { Input, Table, Pagination } from 'antd';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';

import Layout from '../../layouts';
import {
  WrapperHeader,
  WrapperTable,
} from './styled';
import { columnMerchant } from './columns';
import {
  getAllMerchants,
} from '../../services/merchant';
import { LIMIT } from '../../constants';

const { Search } = Input;

const Merchant = () => {

  const [search, setSearch] = useState(false);
  const [listMerchants, setListMerchants] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffSet] = useState(0);
  const history = useHistory();

  useEffect(() => {
    fetchAllMerchants({});
  }, [])

  const fetchAllMerchants = async (params) => {
    try {
      const res = await getAllMerchants(params);
      const { code, data } = res.data;
      if (code === '200') {
        const { rows, meta } = data;
        setListMerchants([...rows]);
        setTotalItem(meta?.total);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
    let offset = (page - 1) * LIMIT;
    setOffSet(offset);
    const params = {
      offset: offset,
      keyword: search.trim(),
    };
    fetchAllMerchants(params);
  }

  const onSearch = (value, e) => {
    const params = {
      offset: offset,
      keyword: value.trim()
    }
    fetchAllMerchants(params);
  }
  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    debouncedSearch(value);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((nextValue) => onSearch(nextValue, null), 2000), []);

  const detail = (id, data) => {
    history.push(`/merchants/detail?merchant_id=${id}`);
  }

  return (
    <Layout>
      <WrapperHeader>
        <Search
          placeholder="Input search text..."
          style={{ width: 500 }}
          onSearch={onSearch}
          onChange={handleChangeSearch}
        />
      </WrapperHeader>
      <WrapperTable>
        <Table
          style={{ overflow: "auto" }}
          bordered
          columns={columnMerchant(detail)}
          dataSource={listMerchants}
          pagination={false}
        />
        <div className="pagination">
          <Pagination
            total={totalItem}
            defaultPageSize={LIMIT}
            current={currentPage}
            onChange={handleChangePage}
          />
        </div>
      </WrapperTable>
    </Layout>
  )
}

export default Merchant;
