import styled from "styled-components";

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  flex-direction: row-reverse;
  .right {
    button {
      margin-left: 10px;
    }
  }
`;


const WrapperSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperTable = styled.div`
  .pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
`;

export {
  WrapperHeader,
  WrapperSettings,
  WrapperTable,
}
