import styled from "styled-components";

const WrapperAvatar = styled.div`
  display: flex;
  justify-content: center;
  .upload {
    background: #fafafa;
    span.anticon {
      display: flex;
      justify-content: center;
    }
  }

  .avatar {
    position: relative;
  }

  .avatar img {
    width: 115px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }

  .avatar .icon-close {
    position: absolute;
    cursor: pointer;
    right: -4px;
    top: -4px;
  }
`;

const WrapperForm = styled.div`
  margin-top: 20px;
  width: 80%;
`;

export {
  WrapperAvatar,
  WrapperForm,
}
