import styled from 'styled-components';

const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
`;

const WrapperTable = styled.div`
  .disable {

    td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6) {
      pointer-events: none;
      background-color: #aaaaaa;
    }
    :hover > td {
      background-color: unset;
    }
  }
  .pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
`;

export {
  WrapperHeader,
  WrapperTable,
}
