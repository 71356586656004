import React, { useCallback, useEffect, useState } from 'react';
import { Input, Table, Pagination } from 'antd';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';

import Layout from '../../layouts';
import { columns } from './columns';
import {
  WrapperHeader,
  WrapperTable,
} from './styled';
import { getAllUsers } from '../../services/user';
import { LIMIT } from '../../constants';

const { Search } = Input;

const User = () => {

  const history = useHistory();
  const [listUsers, setListUsers] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffSet] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchAllUsers({});
  }, []);

  const fetchAllUsers = async (params) => {
    try {
      const res = await getAllUsers(params);
      const { code, data } = res.data;
      if (code === '200') {
        const { rows, meta } = data;
        setListUsers([...rows]);
        setTotalItem(meta?.total);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const edit = (id, data) => {
    history.push(`/users/edit?user_id=${id}`);
  }
  
  const detail = (id, data) => {
    history.push(`/users/detail?user_id=${id}`);
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
    let offset = (page - 1) * LIMIT;
    setOffSet(offset);
    const params = {
      offset: offset,
      keyword: search.trim(),
    };
    fetchAllUsers(params);
  }

  const onSearch = (value, e) => {
    const params = {
      offset: offset,
      keyword: value.trim()
    }
    fetchAllUsers(params);
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    debouncedSearch(value);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((nextValue) => onSearch(nextValue, null), 2000), []);

  return (
    <Layout>
      <WrapperHeader>
        <Search placeholder="Input search text..." style={{ width: 400 }}
          onSearch={onSearch}
          onChange={handleChangeSearch}
        />
      </WrapperHeader>
      <WrapperTable>
        <Table
          style={{ overflow: "auto" }}
          bordered
          columns={columns(edit, detail)}
          dataSource={listUsers}
          pagination={false}
        />
        <div className="pagination">
          <Pagination
            total={totalItem}
            defaultPageSize={LIMIT}
            current={currentPage}
            onChange={handleChangePage} />
        </div>
      </WrapperTable>
    </Layout>
  )
}

export default User;
