import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Tabs, Descriptions, Table } from 'antd';

import Layout from '../../../layouts';
import {
  getMerchantById,
  getAllTransByMerchantId,
} from '../../../services/merchant';
import { WrapperContent } from './styled';
import { columnsTransactions } from './columns';

const { TabPane } = Tabs;

const labelStyle = {
  fontWeight: 600
};

const MerchantDetail = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const { merchant_id: merchantId } = params;
  const [merchantDetail, setMerchantDetail] = useState({});
  const [allTrans, setAllTrans] = useState([]);

  useEffect(() => {
    fetchMerchantDetail(merchantId);
  }, [merchantId]);

  const fetchMerchantDetail = async (id) => {
    try {
      const res = await getMerchantById(id);
      const { data } = res;
      if (data.code === '200') {
        setMerchantDetail(data.data);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const fetchAllTransByMerchantId = async (id) => {
    try {
      const res = await getAllTransByMerchantId(id);
      const { data } = res;
      if (data.code === '200') {
        const newTrans = data.data.sort((a, b) => new Date(b.createdt).getTime() - new Date(a.createdt).getTime() > 0 ? 1 : -1 );
        setAllTrans(newTrans);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleChangeTab = (key) => {
    if (key === '2') {
      fetchAllTransByMerchantId(merchantId);
    }
  }

  return (
    <Layout>
      <WrapperContent>
        <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
          <TabPane key="1" tab={<span className="tab">Information</span>}>
            <Descriptions bordered>
              <Descriptions.Item labelStyle={labelStyle} label="ID" span={3}>{ merchantDetail.id }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Name" >{ merchantDetail.name }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Address" span={2}>{ merchantDetail.address }</Descriptions.Item>
              <Descriptions.Item labelStyle={labelStyle} label="Created at" span={2}>{ merchantDetail.createdAt }</Descriptions.Item>
            </Descriptions>
          </TabPane>
          <TabPane key="2" tab={<span className="tab">All Transaction</span>}>
            <Table
              style={{ overflow: "auto" }}
              bordered
              columns={columnsTransactions}
              dataSource={allTrans}
            />
          </TabPane>
        </Tabs>
      </WrapperContent>
    </Layout>
  )
}

export default MerchantDetail;
