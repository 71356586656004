import styled from "styled-components";

const WrapperContent = styled.div`
  .tab {
    font-size: 1.5em;
    .label {
      font-weight: 700;
    }
  }
`;

export {
  WrapperContent,
}
