import React, { useEffect, useState } from 'react';
import { Table, Modal, Form, Input, message, Button, Upload, InputNumber } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import Layout from '../../layouts';
import {
  getSettingOptions,
  updateSettingOptions,
  uploadPrivateKey,
  initSystem,
} from '../../services/auth';
import {
  heathCheck,
} from '../../services/organization';
import {
  WrapperHeader,
  WrapperTable,
} from './styled';
import { columns } from './columns';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

const styledNoti = { marginLeft: '10px', color: 'red' };

const Setting = () => {
  const [optionSettings, setOptionSettings] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowModalInit, setIsShowModalInit] = useState(false);
  const [currentOption, setCurrentOption] = useState({});
  const [form] = Form.useForm();
  const [formInit] = Form.useForm();

  useEffect(() => {
    fetchAllOptionSettings();
  }, []);

  const fetchAllOptionSettings = async () => {
    try {
      const res = await getSettingOptions();
      const { data } = res;
      if (data.code === '200') {
        setOptionSettings(res.data.data);
      }
    } catch (error) {
      message.error("Have an error !");
    }
  }

  const handleCancel = () => {
    setIsShowModal(false);
  }

  const handleSubmitForm = async (value) => {
    try {
      const params = {
        key: Object.keys(value)[0],
        value: Object.values(value)[0],
      };
      const res = await updateSettingOptions(params);
      const { data } = res;
      if (data.code === '200') {
        message.success("Update success");
        setIsShowModal(false);
        fetchAllOptionSettings();
      }
    } catch (error) {
      const { data } = error.response;
      if (data && data.message) {
        message.error(data.message);
      }
    }
  }

  const edit = (id, data) => {
    setIsShowModal(true);
    setCurrentOption(data);
    let obj = {};
    obj[data.key] = data.value;
    form.setFieldsValue({ ...obj });
  }

  const handleHeathCheck = async () => {
    try {
      const res = await heathCheck();
      const { data } = res;
      if (data.data.status === false) {
        message.error(data.data.message);
      } else {
        message.success(data.data.message);
      }
    } catch (error) {
      message.error("Have an error !");
    }
  }

  const beforeUpload = (file) => {
    // console.log('file', file.type)
  }

  const handleUploadFile = async (options) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('privateKey', file);
    try {
      const res = await uploadPrivateKey(formData);
      const { data } = res;
      if (data && data.code === '200') {
        fetchAllOptionSettings();
        setIsShowModal(false);
        message.success("Upload private key success!");
      }
    } catch (error) {
      const { data } = error.response;
      if (data && data.message) {
        message.error(data.message);
      }
    }
  }

  const renderCurrentOption = (option) => {
    if (option && option.key === 'password') {
      return (
        <Input.Password />
      )
    }
    if (option && option.key === 'privateKey') {
      return (
        <div>
           <Upload
              name="privateKey"
              accept=".PEM,.DER"
              multiple={false}
              showUploadList={false}
              listType="text"
              beforeUpload={beforeUpload}
              customRequest={handleUploadFile}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <small className="notification" style={styledNoti} >File format ssh (Example: .pem, .der,...)</small>
        </div>
      )
    }
    return <Input />
  }

  const renderContentInit = () => {
    return (
      <Form
        { ...layout }
        form={formInit}
        onFinish={handleSubmitFormInit}
      >
        <Form.Item
          label="Host"
          name="host"
          rules={[
            { required: true, message: 'Required!' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Port"
          name="port"
          rules={[
            { required: true, message: 'Required!' },
            { type: 'number', message: 'Type number!' }
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            { required: true, message: 'Required!' }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Required!' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Dir Makefile"
          name="dirMakefile"
          rules={[
            { required: true, message: 'Required!' }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    )
  }

  const handleSubmitFormInit = async (value) => {
    const newValue = {
      ...value,
      port: Number(value.port)
    }
    try {
      const res = await initSystem(newValue);
      const { data } = res;
      if (data && data.code === '200') {
        setIsShowModalInit(false);
        fetchAllOptionSettings();
        message.success("Initial system success!");
        formInit.resetFields();
      }
    } catch (error) {
      const { data } = error.response;
      if (data && data.message) {
        message.error(data.message);
      }
    }
  }

  return (
    <Layout>
      <WrapperHeader>
        <div className="right">
          <Button type="primary" ghost onClick={() => setIsShowModalInit(true)} >Init system</Button>
          <Button type="primary" onClick={handleHeathCheck}>Health Check</Button>
        </div>
      </WrapperHeader>
      <WrapperTable>
        <Table
          style={{ overflow: "auto" }}
          bordered
          columns={columns(edit)}
          dataSource={optionSettings}
        />
      </WrapperTable>
      { isShowModal && (
        <Modal
          title="Edit settings"
          visible={isShowModal}
          onCancel={handleCancel}
          onOk={form.submit}
        >
          <Form
            { ...layout }
            form={form}
            onFinish={handleSubmitForm}
          >
            <Form.Item
              label={ currentOption.key }
              name={currentOption.key}
              rules={[
                { required: true, message: 'Required!' }
              ]}
            >
              { renderCurrentOption(currentOption) }
            </Form.Item>
          </Form>
        </Modal>
      ) }

      {isShowModalInit && (
        <Modal
          title="Initial System"
          visible={isShowModalInit}
          onCancel={() => setIsShowModalInit(false)}
          onOk={formInit.submit}
        >
          { renderContentInit() }
        </Modal>
      )}
    </Layout>
  )
}

export default Setting;
