import { Button, Space, Tooltip } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';

const renderAction = (id, data, edit, detail) => {
  return (
    <Space>
      <Tooltip
        title="Detail"
      >
        <Button type="primary" onClick={() => detail(id, data)} icon={<EyeOutlined />} />
      </Tooltip>
      <Tooltip
        title="Edit"
      >
        <Button danger type="primary" onClick={() => edit(id, data)} icon={<EditOutlined />} />
      </Tooltip>
    </Space>
  );
};

export const columns = (edit, detail) => {
  return [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
      editable: true,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      editable: true,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "30%",
      editable: true,
      align: "center",
    },
    {
      title: "WalletID",
      dataIndex: "walletId",
      width: "35%",
      editable: true,
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center",
      render: (id, data) => renderAction(id, data, edit, detail),
    },
  ];
};