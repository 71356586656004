import React from 'react';

import Layout from '../../layouts';

const Home = () => {

  return (
    <Layout
      
    >
      Home
    </Layout>
  )
}

export default Home;
