import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  PlusOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Form, Input,
  Button, Upload,
  message, Spin,
} from 'antd';
import heic2any from 'heic2any';

import Layout from '../../../layouts';
import {
  WrapperAvatar,
  WrapperForm,
} from './styled';
import {
  getDetailUserById,
  updateUserInfo,
  uploadAvatarUser,
} from '../../../services/user';
import { EDIT_USER } from '../../../constants/validate';
import { APP_API_URL } from '../../../configs';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: {
    offset: 6,
    span: 16,
  },
};

const UserEdit = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [currentImage, setCurrentImage] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [extension, setExtension] = useState('');
  const params = queryString.parse(location.search);
  const { user_id: userId } = params;

  useEffect(() => {
    const params = {
      user_id: userId,
    };
    fetchUserDetail(params);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const fetchUserDetail = async (params) => {
    try {
      const res = await getDetailUserById(params);
      const { code, data } = res.data;
      if (code === '200') {
        form.setFieldsValue({
          email: data.email,
          name: data.name,
          phone: data.phone,
          walletId: data.walletId,
        });
        const { facial } = data;
        setCurrentImage(facial ? facial.src : '');
        const img = facial ? facial.src : ''
        const extension = img.split('.')[1];
        if (extension === 'HEIC') {
          setExtension(extension);
          setLoading(true);
          convertToHeic(APP_API_URL + img);
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSetDisable = () => {
    setDisabled(false);
  }

  const handleUpdateUser = async (value) => {
    try {
      const params = {
        name: value.name,
        email: value.email.trim(),
      };
      const res = await updateUserInfo(userId, params);
      const { code } = res.data;
      if (code === '200') {
        setDisabled(true);
        message.success('Update user info success');
      }
    } catch (error) {
      const { data } = error.response;
      if (data?.message?.[0]?.email?.constraints?.[0] === 'EXISTED') {
        message.error('Email already exists');
      }
    }
  }

  const uploadButton = (
    <div style={{  border: '1px dashed #d9d9d9', padding: '30px', borderRadius: '2px' }}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64Image = (img, callback) => {
    if (img) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  }

  const handleChangeAvatar = (info) => {
    if (info.file.originFileObj.type === '') {
      const blob = info.file.originFileObj;
      setLoadingChange(true);
      heic2any({
        blob: blob,
        toType: "image/jpg",
        quality: 1,
      }).then(result => {
        const url = URL.createObjectURL(result);
        setImgUrl(url);
        setLoadingChange(false);
      }).catch(err => {
        console.log('err', err);
        setLoadingChange(false);
      });
    } else {
      getBase64Image(info.file.originFileObj, (imageUrl) => {
        setImgUrl(imageUrl);
      });
    }
  }

  const beforeUpload = (file) => {
    const validate = file.size / 1024 / 1024 > 30;
    if (validate) {
      setImgUrl('');
      message.error("Image must be less than 30M");
      return false;
    }
    return true;
  }

  const handleUploadFile = async (options) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('facial', file);
    setLoading(true);
    try {
      const res = await uploadAvatarUser(userId, formData);
      const { code } = res.data;
      if (code === '200') {
        message.success('Update Facial Success');
        setLoading(false);
      }
    } catch (error) {
      const { data } = error.response;
      message.error(data.message);
      setImgUrl('');
      setLoading(false);
    }
  }

  const handleRemoveImageCurrent = () => {
    setImgUrl('');
    setCurrentImage('');
  }

  const renderImage = () => {
    return (
      <div className="avatar">
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined />}
        >
          { (imgUrl || currentImage) && (<CloseCircleOutlined className="icon-close" onClick={handleRemoveImageCurrent} />) }
          <img src={currentImage ?  `${ extension === 'HEIC' ? currentImage : APP_API_URL + currentImage }` : imgUrl} alt="avatar" />
        </Spin>
      </div>
    )
  }

  const convertToHeic = (url) => {
    fetch(url)
    .then((res) => res.blob())
    .then((blob) =>
      heic2any({
        blob,
        toType: "image/jpeg",
        quality: 1, // cuts the quality and size by half
      })
    )
    .then((conversionResult) => {
      const url = URL.createObjectURL(conversionResult);
      setCurrentImage(url);
      setLoading(false);
    })
    .catch((e) => {
        // see error handling section
        console.log('e', e)
        setLoading(false);
    });
  }

  return (
    <Layout>
      <WrapperAvatar>
        {
          imgUrl || currentImage ? 
          renderImage() :
          (<Spin
            spinning={loadingChange}
            indicator={<LoadingOutlined />}
          >
            <Upload
              accept=".heic,image/*"
              name="avatar"
              className="upload"
              onChange={handleChangeAvatar}
              multiple={false}
              listType="picture"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={handleUploadFile}
            >
              {uploadButton}
            </Upload>
          </Spin> )
        }
      </WrapperAvatar>
      <WrapperForm>
        <Form
          { ...layout }
          name="form-edit-user"
          className="form-edit"
          form={form}
          onFinish={handleUpdateUser}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: EDIT_USER.EMAIL },
              // eslint-disable-next-line no-useless-escape
              { pattern: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
                message: EDIT_USER.TYPE_EMAIL
            } 
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: EDIT_USER.NAME },
              { min: 4, message: EDIT_USER.NAME_LENGTH },
              { max: 15, message: EDIT_USER.NAME_LENGTH },
              { pattern: new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i),
                message: 'Sorry, only letters (a-z) and spaces are allowed'
              }
            ]}
          >
            <Input disabled={disabled} maxLength={15} />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="WalletID"
            name="walletId"
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
           { ...tailLayout }
          >
            <Button type="default" onClick={handleSetDisable} disabled={!disabled}>
              Edit
            </Button>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 20 }} disabled={disabled} >
              Save
            </Button>
          </Form.Item>
        </Form>
      </WrapperForm>
    </Layout>
  )
}

export default UserEdit;
