import api from "./api";
import { LIMIT } from '../constants';

export const getAllUsers = (params) => {
  let url = `/api/v1/admin/user?offset=${params.offset ? params.offset : 0}&limit=${LIMIT}&keyword=${params.keyword ? params.keyword : ''}`;
  return api.get(url);
}

export const getDetailUserById = (params) => {
  let url = `/api/v1/admin/user/${params.user_id}`;
  return api.get(url);
}

export const uploadAvatarUser = (user_id, data) => {
  let url = `/api/v1/admin/user/${user_id}/facial`;
  return api.post(url, data);
}

export const updateUserInfo = (user_id, data) => {
  let url = `/api/v1/admin/user/${user_id}`;
  return api.put(url, data);
}

export const getAllTransactionsByWalletId = (walletId) => {
  let url = `/api/v1/admin/transactions/${walletId}`;
  return api.get(url);
}
