import React from 'react';
import { Menu } from 'antd';
import {
  UserOutlined,
  HomeOutlined,
  GlobalOutlined,
  SettingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useLocation, NavLink } from 'react-router-dom';

import {
  WrapperSidebar,
} from './styled';

const Sidebar = (props) => {
  const {
    collapsed,
  } = props;

  const { pathname } = useLocation();

  return (
    <WrapperSidebar trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={pathname}>
        <Menu.Item key="/" icon={<HomeOutlined />}>
          <NavLink to="/">Home</NavLink>
        </Menu.Item>
        <Menu.Item key="/users" icon={<UserOutlined />}>
          <NavLink to="/users">User</NavLink>
        </Menu.Item>
        <Menu.Item key="/organizations" icon={<GlobalOutlined />}>
          <NavLink to="/organizations">Organization</NavLink>
        </Menu.Item>
        <Menu.Item key="/merchants" icon={<SearchOutlined />}>
          <NavLink to="/merchants">Merchant</NavLink>
        </Menu.Item>
        <Menu.Item key="/settings" icon={<SettingOutlined />}>
          <NavLink to="/settings">Settings</NavLink>
        </Menu.Item>
      </Menu>
    </WrapperSidebar>
  )
}

export default Sidebar;
