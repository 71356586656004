import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Form, message, Input, Pagination } from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import { debounce } from 'lodash';

import Layout from '../../layouts';
import ModalComponent from '../../components/modal';
import {
  WrapperHeader,
  WrapperTable,
} from './styled';
import { columns } from './columns';
import {
  getAllOrganizations,
  createOrganization,
  updateOrganization,
} from '../../services/organization';
import { LIMIT } from '../../constants';

const { Search } = Input;

const Organization = () => {

  const [listOrganizations, setListOrganizations] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentIdOrg, setCurrentIdOrg] = useState('');
  const [search, setSearch] = useState('');
  const [totalItem, setTotalItem] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffSet] = useState(0);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAllOrganizations({});
  }, []);

  const fetchAllOrganizations = async (params) => {
    try {
      const res = await getAllOrganizations(params);
      const { code, data } = res.data;
      if (code === '200') {
        const { rows, meta } = data;
        setListOrganizations([...rows]);
        setTotalItem(meta?.total);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const edit = (id, data) => {
    setIsUpdate(true);
    setIsShowModal(true);
    setCurrentIdOrg(id);
    form.setFieldsValue({
      id: id,
      orgKey: data.orgKey,
      icon: data.icon,
      url: data.url,
      active: data.active,
    });
  }

  const handleCancel = () => {
    setIsShowModal(false);
    setIsUpdate(false);
    form.resetFields();
  }

  const handleShowModalAdd = () => {
    setIsShowModal(true);
    setIsUpdate(false);
    form.resetFields();
  }

  const handleSubmitForm = (value) => {
    if (!isUpdate) {
      handleAddOrganization(value);
    } else {
      const params = {
        ...value,
        id: currentIdOrg,
      }
      handleUpdateOrganization(params);
    }
  }

  const handleAddOrganization = async (params) => {
    try {
      const res = await createOrganization(params);
      const { data } = res;
      if (data.code === '200') {
        message.success("Add success");
        const paramsGetAll = {
          offset: offset,
          keyword: search.trim(),
        }
        fetchAllOrganizations(paramsGetAll);
        setIsShowModal(false);
      }
    } catch (error) {
      const { data } = error.response;
      message.error(data.message);
    }
  }

  const handleUpdateOrganization = async (params) => {
    try {
      const res = await updateOrganization(params);
      const { data } = res;
      if (data.code === '200') {
        const paramsGetAll = {
          offset: offset,
          keyword: search.trim(),
        }
        fetchAllOrganizations(paramsGetAll);
        setIsShowModal(false);
        message.success("Update success");
      }
    } catch (error) {
      const { data } = error.response;
      message.error(data.message);
    }
  }

  const row = (record, index) => {
    if (!record.active) {
      return 'disable'
    }
    return '';
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
    let offset = (page - 1) * LIMIT;
    setOffSet(offset);
    const params = {
      offset: offset,
      keyword: search.trim(),
    };
    fetchAllOrganizations(params);
  }

  const onSearch = (value, e) => {
    const params = {
      offset: offset,
      keyword: value.trim()
    }
    fetchAllOrganizations(params);
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    debouncedSearch(value);
  }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const debouncedSearch = useCallback(debounce((nextValue) => onSearch(nextValue, null), 2000), []);

  return (
    <Layout>
      <WrapperHeader>
        <Search
          placeholder="Input search text..."
          style={{ width: 400 }}
          onSearch={onSearch}
          onChange={handleChangeSearch}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleShowModalAdd}
        >
          Add
        </Button>
      </WrapperHeader>
      <WrapperTable>
        <Table
          rowClassName={(record, index) => row(record, index)}
          style={{ overflow: "auto" }}
          bordered
          columns={columns(edit)}
          dataSource={listOrganizations}
          pagination={false}
        />
        <div className="pagination">
          <Pagination
            total={totalItem}
            defaultPageSize={LIMIT}
            current={currentPage}
            onChange={handleChangePage} />
        </div>
      </WrapperTable>

      { isShowModal && (
         <ModalComponent
          title={ isUpdate ? "Update Organization" : "Add Organization" }
          isShowModal={isShowModal}
          handleCancel={handleCancel}
          form={form}
          isUpdate={isUpdate}
          handleSubmitForm={handleSubmitForm}
        />
      ) }

    </Layout>
  )
}

export default Organization;
