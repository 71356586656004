import api from './api';
import { LIMIT } from '../constants';

export const getAllMerchants = (params) => {
  let url = `/api/v1/admin/merchant?offset=${params.offset ? params.offset : 0}&limit=${LIMIT}&keyword=${params.keyword ? params.keyword : ''}`;
  return api.get(url);
}

export const getMerchantById = (id) => {
  let url = `/api/v1/admin/merchant/${id}`;
  return api.get(url);
}

export const getAllTransByMerchantId = (id) => {
  let url = `/api/v1/admin/transactions/merchant/${id}`;
  return api.get(url);
}
