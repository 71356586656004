import api from './api';

export const login = (data) => {
  let url = '/api/v1/admin/auth/login';
  return api.post(url, data);
}

export const getMe = () => {
  let url = '/api/v1/admin/auth/me';
  return api.get(url);
}

export const getSettingOptions = () => {
  let url = '/api/v1/admin/config';
  return api.get(url);
}

export const updateSettingOptions = (params) => {
  let url = '/api/v1/admin/config';
  return api.post(url, params);
}

export const initSystem = (params) => {
  let url = '/api/v1/admin/init-system';
  return api.post(url, params);
}

export const uploadPrivateKey = (params) => {
  let url = '/api/v1/admin/private-key';
  return api.post(url, params);
}
