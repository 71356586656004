import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.css";

import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/login';
import Home from './pages/home';
import User from './pages/users';
import UserDetail from './pages/users/detail';
import UserEdit from './pages/users/edit';
import Organization from './pages/organizations';
import Setting from './pages/settings';
import Merchant from './pages/merchant';
import MerchantDetail from './pages/merchant/detail';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/users" component={User} />
        <PrivateRoute exact path="/users/detail" component={UserDetail} />
        <PrivateRoute exact path="/users/edit" component={UserEdit} />
        <PrivateRoute exact path="/organizations" component={Organization} />
        <PrivateRoute exact path="/settings" component={Setting} />
        <PrivateRoute exact path="/merchants" component={Merchant} />
        <PrivateRoute exact path="/merchants/detail" component={MerchantDetail} />
      </Switch>
    </Router>
  )
}

export default App;
