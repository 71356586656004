import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import {
  upperCaseFirstLetter,
} from '../../helpers';

const BreadcrumbComponent = () => {
  const location = useLocation();
  const { pathname } = location;

  const pathnames = pathname.split('/').filter(item => item);

  return (
    <Breadcrumb>
      { pathnames.length > 0 ? (
        <Breadcrumb.Item>
          <Link to="/" >Home</Link>
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item>
          Home
        </Breadcrumb.Item>
      ) }
      { pathnames.map((name, index) => {
        const linkTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Breadcrumb.Item key={index}>{ upperCaseFirstLetter(name) }</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index}>
            <Link to={`${linkTo}`} >{ upperCaseFirstLetter(name) }</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  )
}

export default BreadcrumbComponent;
