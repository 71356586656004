import styled from 'styled-components';

const WrapperLogin = styled.div`
  height: 100vh;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperForm = styled.div`
  background: white;
  width: 400px;
  height: 400px;
  padding: 40px;
  border-radius: 15px;

  .logo {
    text-align: center;
    margin-bottom: 50px;
    span {
      font-weight: 700;
      color: wheat;
      font-size: 1.5em;
    }
  }

  .form {
    .btn-login {
      width: 100%;
    }
  }
`;

export {
  WrapperLogin,
  WrapperForm,
}
