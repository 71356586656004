import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input,
  Button, Spin,
  message,
} from 'antd';
import {
  LoadingOutlined,
  MailOutlined,
  LockOutlined,
} from '@ant-design/icons';

import { Icons } from '../../constants/images';
import { LOGIN } from '../../constants/validate';
import {
  WrapperLogin,
  WrapperForm,
} from './styled';
import { login } from '../../services/auth';

const Login = () => {
  const accessToken = localStorage.getItem('access_token');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (accessToken) {
      history.push('/');
    }
  }, [history, accessToken]);

  const handleLogin = async (value) => {
    setIsLoading(true);
    try {
      const res = await login(value);
      const { code, data } = res.data;
      if (code === "200") {
        localStorage.setItem("access_token", data?.accessToken);
        setIsLoading(false);
        window.location.href = "/";
      }
    } catch (error) {
      const { data } = error.response;
      message.error(data?.message);
      setIsLoading(false);
    }
  }

  return (
    <WrapperLogin>
      <WrapperForm>
        <div className="logo">
          <img src={Icons.IconLogo} alt="logo" />
          <span>Admin</span>
        </div>
        <div className="form">
          <Form
            name="form-login"
            autoComplete="off"
            onFinish={handleLogin}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: LOGIN.EMAIL },
                { type: 'email', message: LOGIN.TYPE }
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Enter email..."
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: LOGIN.PASSWORD },
                { min: 8, message: LOGIN.MIN },
                { max: 20, message: LOGIN.MAX }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter password..." />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn-login">
                { isLoading ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: 'wheat' }} spin />} />) : 'Login' }
              </Button>
            </Form.Item>
          </Form>
        </div>
      </WrapperForm>
    </WrapperLogin>
  )
}

export default Login;
