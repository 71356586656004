import axios from 'axios';
import { APP_API_URL } from '../configs';

const api = axios.create({
  baseURL: APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + localStorage.getItem('access_token')
  }
});

export default api;
