import { Button, Space, Tooltip } from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import {
  IconsOrganization,
} from '../../constants/images';

const renderAction = (id, data, edit) => {
  return (
    <Space>
      <Tooltip
        title="Edit"
      >
        <Button danger type="primary" onClick={() => edit(id, data)} icon={<EditOutlined />}> Edit </Button>
      </Tooltip>
    </Space>
  );
};

const renderIcon = (icon) => {
  const index = IconsOrganization.findIndex(x => x.value === icon);
  return (
    <img src={ index !== -1 ? IconsOrganization[index].path : ''} alt="icon" width="30"  />
  )
}

const renderURL = (url) => {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">{ url }</a>
    )
  }
}

export const columns = (edit) => {
  return [
    {
      title: "No.",
      dataIndex: "id",
      width: "5%",
      editable: true,
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "15%",
      editable: true,
      align: "center",
    },
    {
      title: "Pass Organization",
      dataIndex: "passOrg",
      width: "15%",
      editable: true,
      align: "center",
    },
    {
      title: "Organization Key",
      dataIndex: "orgKey",
      width: "15%",
      editable: true,
      align: "center",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      width: "10%",
      editable: true,
      align: "center",
      render: (icon) => renderIcon(icon),
    },
    {
      title: 'URL',
      dataIndex: "url",
      width: "30%",
      editable: true,
      align: "center",
      render: (url) => renderURL(url),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "10%",
      align: "center",
      render: (id, data) => renderAction(id, data, edit),
    },
  ];
}
