import styled from 'styled-components';

const WrapperOption = styled.div`
   display: flex;
  justify-content: center;
  margin-top: 5px;
`;

export {
  WrapperOption,
}
