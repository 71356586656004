import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';

import Header from './Header';
import Sidebar from './Sidebar';
import Breadcrumb from '../components/breadcrumb';
import {
  WrapperContent,
} from './styled';
import { getMe } from '../services/auth';

const LayoutComponent = (props) => {
  const {
    children
  } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    handleGetMe();
  }, []);

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const handleGetMe = async () => {
    try {
      const res = await getMe();
      const { code, data } = res.data;
      if (code === '200') {
        setUserInfo({ ...data });
      }
    } catch (error) {
      
    }
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.href="/login";
  }

  return (
    <Layout>
      <Sidebar
        collapsed={collapsed}
      />
      <Layout className="site-layout">
        <Header
          collapsed={collapsed}
          toggle={toggle}
          handleLogout={handleLogout}
          userInfo={userInfo}
        />

        <WrapperContent>
          <div className="breadcrumb">
            <Breadcrumb />
          </div>
          <div className="content-wrapper">
            { children }
          </div>
        </WrapperContent>
      </Layout>
    </Layout>
  )
}

export default LayoutComponent;
