import { Descriptions } from 'antd';
import _ from 'lodash';
import { convertStringKey, shortenAddress } from '../../../helpers';
import { FABRIC_URL } from '../../../configs';

const renderStatus = (action) => {
  return action ? 'Approved' : 'Rejected';
}

const renderDataShare = (action) => {
  const Vaccine = action.Vaccine ? action.Vaccine : ''
  const MM2H = action.MM2H ? action.MM2H : '';
  const doses = Vaccine.doses ? Vaccine.doses : '';
  const newOtherData = _.omit(action, ['Vaccine', 'MM2H']);


  const renderOtherData = (data) => {
    return Object.keys(data).map((value, index) => {
      const dataForValue = data[value];
      return (
        <Descriptions.Item key={index} label={value} span={12} >
          { Object.keys(dataForValue).map((item, index2) => {
            return (
              <>
                { convertStringKey(item) } : { dataForValue[item] }
                <br />
              </>
            )
          }) }
        </Descriptions.Item>
      )
    })
  }

  return (
    <Descriptions bordered>
      { Vaccine && (
        <Descriptions.Item label="Vaccine" span={12}>
          <Descriptions.Item>
            UniqueID: { Vaccine.uniqueIdentificationNo }
            <br />
            Issued by: { Vaccine.issulingOrganisation }
            <hr />
          </Descriptions.Item>
          { doses && doses.map((dose, index) => {
            return (
              <Descriptions.Item key={index}>
                Dose: { index + 1 }
                <br />
                Batch: { dose.batch }
                <br />
                Day issued: { dose.date }
                <br />
                Manufacturer: { dose.manufacturer }
                <hr />
              </Descriptions.Item>
            )
          }).reverse() }
        </Descriptions.Item>
      )}
      { MM2H && (
        <Descriptions.Item label="MM2H" span={12}>
          Passport Number: { MM2H.passportNumber }
          <br />
          Date expired: { MM2H.expiryDate }
        </Descriptions.Item>
      )}
      { !_.isEmpty(newOtherData) && renderOtherData(newOtherData) }
    </Descriptions>
  )
}

const renderMerchant = (action) => {
  const name = action.name ? action.name : '';
  const address = action.address ? action.address : '';
  return (
    <Descriptions bordered>
      { name && (
      <Descriptions.Item label="Name" span={12}>
        { name }
      </Descriptions.Item>
      )}
      { address && (
      <Descriptions.Item label="Address" span={12}>
      { address }
      </Descriptions.Item>
      )}
    </Descriptions>
  )
}

const renderWarningLevel = (action) => {
  return action ? action.status : '';
}

export const columnsPremiseEntry = [
  {
    title: "No.",
    dataIndex: "id",
    width: "5%",
    editable: true,
    align: "center",
  },
  {
    title: "Type of access",
    dataIndex: "category",
    width: "10%",
    editable: true,
    align: "center",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    width: "22%",
    editable: true,
    align: "center",
    render: (action) => renderMerchant(action)
  },
  {
    title: "Data share",
    dataIndex: "dataShare",
    width: "40%",
    editable: true,
    align: "center",
    render: (action) => renderDataShare(action)
  },
  {
    title: "Warning level",
    dataIndex: "enforcement",
    width: "3%",
    editable: true,
    align: "center",
    render: (action) => renderWarningLevel(action)
  },
  {
    title: "Status",
    dataIndex: "action",
    width: "10%",
    editable: true,
    align: "center",
    render: (action) => renderStatus(action)
  },
  {
    title: "Checkin at",
    dataIndex: "checkinAt",
    width: "10%",
    editable: true,
    align: "center",
  },
];

const renderLinkTx = (txhash) => {
  return <a href={FABRIC_URL + `?tab=transactions&transId=${txhash}`} target="_blank" rel="noreferrer" >{ shortenAddress(txhash) }</a>
}

export const columnsTransactions = [
  {
    title: "Tx Hash",
    dataIndex: "txhash",
    width: "30%",
    editable: true,
    align: "center",
    render: (txhash) => renderLinkTx(txhash)
  },
  {
    title: "Chain Code",
    dataIndex: "chaincodename",
    width: "20%",
    editable: true,
    align: "center",
  },
  {
    title: "Validation Code",
    dataIndex: "validation_code",
    width: "15%",
    editable: true,
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "5%",
    editable: true,
    align: "center",
  },
  {
    title: "Created at",
    dataIndex: "createdt",
    width: "30%",
    editable: true,
    align: "center",
  },
]
