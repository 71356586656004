import { Layout } from "antd";
import styled from "styled-components";

const { Header, Content, Sider } = Layout;

const WrapperHeader = styled(Header)`
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  padding-right: 30px;

  .box-avatar {
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      margin-left: 5px;
    }
  }

  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: #1890ff;
  }
`;

const WrapperContent = styled(Content)`
  margin: 24px 16px;
  padding: 24;
 

  .breadcrumb {
    margin-bottom: 10px;
  }

  .content-wrapper {
    background: #fff;
    min-height: 85vh;
    padding: 20px 30px;
  }
`;

const WrapperSidebar = styled(Sider)`
  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
`;

export {
  WrapperHeader,
  WrapperContent,
  WrapperSidebar,
}
