import api from './api';
import { LIMIT } from '../constants';

export const getAllOrganizations = (params) => {
  let url = `/api/v1/admin/category?offset=${params.offset ? params.offset : 0}&limit=${LIMIT}&keyword=${params.keyword ? params.keyword : ''}`;
  return api.get(url);
}

export const createOrganization = (params) => {
  let url = '/api/v1/admin/category';
  return api.post(url, params);
}

export const initCategory = (params) => {
  let url = `/api/v1/admin/category-init/${params.id}`;
  return api.get(url);
}

export const updateOrganization = (params) => {
  let url = '/api/v1/admin/category-update';
  return api.post(url, params);
}

export const heathCheck = () => {
  let url = '/api/v1/admin/health-check';
  return api.get(url);
}
